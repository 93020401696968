import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";

import { Box, Button, useTheme } from "@mui/material";
import { colorTokens } from "../../theme";

const ContentUpload = ({
  setPayload,
  accept,
  displayWidth,
  displayHeight,
  name,
}) => {
  const [fileData, setFileData] = useState();

  const theme = useTheme();
  const colors = colorTokens(theme.palette.mode);

  useEffect(() => {
    if (fileData) {
      setPayload(fileData);
    }
  }, [fileData]);

  const renderImagePreview = () => {
    if (fileData) {
      return (
        <Box
          sx={{
            display: "flex",
            width: displayWidth,
            height: displayHeight,
            position: "relative",
            marginTop: "20px",
          }}
        >
          <img
            src={URL.createObjectURL(fileData)}
            alt="preview"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      );
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="start" mt="20px">
        <Button
          color="secondary"
          sx={{ mr: "8px", color: colors.grey[700] }}
          variant="contained"
          component="label"
          disabled={fileData ? true : false}
        >
          {`Select ${name}`}
          <input
            type="file"
            onChange={(e) => setFileData(e.target.files[0])}
            hidden
            accept={`${accept}`}
          />
        </Button>

        <Button
          color="secondary"
          variant="contained"
          sx={{
            backgroundColor: colors.redAccent[600],
            color: colors.grey[100],
          }}
          onClick={() => {
            setFileData(null);
          }}
          disabled={fileData ? false : true}
        >
          {`Remove ${name}`}
        </Button>
      </Box>
      {accept === "images/*" && (
        <Box display="flex" justifyContent="center">
          {renderImagePreview()}
        </Box>
      )}
    </Box>
  );
};

export default ContentUpload;
