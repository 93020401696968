import { Routes, Route } from "react-router-dom";

import LoginPage from "../pages/LoginPage";
import HomePage from "../pages/HomePage";
import { useAuth } from "../context/AppProvider";
import ArtistPage from "../pages/ArtistPage";
import UsersPage from "../pages/UsersPage";
import InvoicePage from "../pages/InvoicePage";
import AddArtistPage from "../pages/AddArtistPage";
import BarPage from "../pages/BarPage";
import PiePage from "../pages/PiePage";
import LinePage from "../pages/LinePage";
import FAQPage from "../pages/FAQPage";
import GeographyPage from "../pages/GeographyPage";
import EventsPage from "../pages/EventsPage";
import DashboardPage from "../pages/DashboardPage";
import VideoPage from "../pages/VideoPage";
import AddVideoPage from "../pages/AddVideoPage";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<DashboardPage />} />
      <Route path="/artists" element={<ArtistPage />} />
      <Route path="/addArtist" element={<AddArtistPage />} />
      <Route path="/videos" element={<VideoPage />} />
      <Route path="/addVideo" element={<AddVideoPage />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/invoices" element={<InvoicePage />} />
      <Route path="/bar" element={<BarPage />} />
      <Route path="/pie" element={<PiePage />} />
      <Route path="/line" element={<LinePage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/geography" element={<GeographyPage />} />
      <Route path="/events" element={<EventsPage />} />
    </Routes>
  );
};

export default AuthRoutes;
