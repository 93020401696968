import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  useTheme,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { colorTokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import ArtistContentUpload from "../../components/ArtistContentUpload";
import axios from "axios";
import { baseURL } from "../../config";

const jwt = localStorage.getItem("token");

const headers = {
  "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${jwt}`,
};

const AddArtistPage = () => {
  const [headerImageData, setHeaderImageData] = useState();
  const [profileImageData, setProfileImageData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = colorTokens(theme.palette.mode);
  const navigate = useNavigate();

  const formData = new FormData();

  const handleFormSubmit = async (values) => {
    const endorsed = values.endorsed.split(", ");
    const playedFor = values.playedFor.split(", ");
    values = {
      ...values,
      endorsed,
      playedFor,
      headerImageData,
      profileImageData,
    };
    formData.append("name", values.name);
    formData.append("country", values.country);
    formData.append("playedForFeatured", values.playedForFeatured);
    formData.append("endorsed", endorsed);
    formData.append("playedFor", playedFor);
    formData.append("headerImageData", headerImageData);
    formData.append("profileImageData", profileImageData);

    try {
      setError(false);
      setLoading(true);
      await axios.post(`${baseURL}/api/v1/artists`, formData, {
        headers,
      });
      navigate("/artists");
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE ARTIST" subtitle="Create a New Artist Profile" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Country"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="country"
                error={!!touched.country && !!errors.country}
                helperText={touched.country && errors.country}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Endorsed by... (separate by comma)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.endorsed}
                name="endorsed"
                error={!!touched.endorsed && !!errors.endorsed}
                helperText={touched.endorsed && errors.endorsed}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Mainly drums for..."
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.playedForFeatured}
                name="playedForFeatured"
                error={
                  !!touched.playedForFeatured && !!errors.playedForFeatured
                }
                helperText={
                  touched.playedForFeatured && errors.playedForFeatured
                }
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Also drums for... (separate by comma)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.playedFor}
                name="playedFor"
                error={!!touched.playedFor && !!errors.playedFor}
                helperText={touched.playedFor && errors.playedFor}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <ArtistContentUpload
                type="Image"
                setPayload={setHeaderImageData}
                accept="image/*"
                displayWidth="1140px"
                displayHeight="208px"
                name="Header Image"
              />
              <ArtistContentUpload
                type="Image"
                setPayload={setProfileImageData}
                accept="image/*"
                displayWidth="265px"
                displayHeight="181px"
                name="Profile Image"
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              {loading ? (
                <CircularProgress
                  color="secondary"
                  sx={{ marginRight: "20px" }}
                />
              ) : (
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ mr: "8px", color: colors.grey[700] }}
                >
                  Create New Artist
                </Button>
              )}

              <Button
                variant="contained"
                sx={{
                  backgroundColor: colors.redAccent[600],
                  color: colors.grey[100],
                }}
                onClick={() => navigate("/artists")}
              >
                Cancel
              </Button>
            </Box>
            {error && (
              <Box textAlign="end" sx={{ marginTop: "10px" }}>
                <Typography color="red">Error Please Try Again</Typography>
              </Box>
            )}
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  country: yup.string().required("required"),
  endorsed: yup.string(),
  playedForFeatured: yup.string().required("required"),
  playedFor: yup.string(),
});
const initialValues = {
  name: "",
  country: "",
  endorsed: "",
  playedForFeatured: "",
  playedFor: "",
};

export default AddArtistPage;
