import { useState, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  useTheme,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { colorTokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import ArtistContentUpload from "../../components/ArtistContentUpload";
import axios from "axios";
import VideoContentUpload from "../../components/VideoContentUpload";

const jwt = localStorage.getItem("token");

const headers = {
  "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${jwt}`,
};

const AddVideoPage = () => {
  const [PDFData, setPDFData] = useState();
  const [profileImageData, setProfileImageData] = useState();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const MAX_CHUNK_SIZE = 1024 * 1024 * 5; // 5MB

  // Video Upload Functionality
  const [videoFile, setVideoFile] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [uploadedChunks, setUploadedChunks] = useState([]);

  const handleFileChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  const handleRemove = async () => {
    try {
      await axios.delete(`/remove/${videoFile.name}`);
      setVideoFile(null);
      setUploadUrl(null);
      setUploadedChunks([]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleVideoUpload = () => {
    const fileSize = videoFile.size;
    const chunkSize = MAX_CHUNK_SIZE;
    const chunks = Math.ceil(fileSize / chunkSize);

    // Create an array of promises that will upload each chunk in parallel
    const promises = [];
    for (let i = 0; i < chunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(fileSize, (i + 1) * chunkSize);
      const chunk = videoFile.slice(start, end);
      const formData = new FormData();
      formData.append("chunk", chunk);
      formData.append("index", i);
      formData.append("totalChunks", chunks);
      formData.append("fileName", videoFile.name);
      promises.push(
        axios.post("/upload", formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        })
      );
    }
    console.log("Hello");

    // Wait for all the parallel uploads to complete
    return Promise.all(promises);
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = colorTokens(theme.palette.mode);
  const navigate = useNavigate();

  const formData = new FormData();

  const handleFormSubmit = async (values) => {
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("PDFData", PDFData);

    try {
      setError(false);
      setLoading(true);
      handleVideoUpload();
      console.log(formData);
      //   await axios.post("/api/v1/videos", formData, {
      //     headers,
      //   });
      //   navigate("/videos");
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE VIDEO" subtitle="Upload a New Video" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
                multiline
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Box sx={{ pr: "40px" }}>
                <Box display="flex" justifyContent="start" mt="20px">
                  <Button
                    color="secondary"
                    sx={{ mr: "8px", color: colors.grey[700] }}
                    variant="contained"
                    component="label"
                    disabled={videoFile ? true : false}
                  >
                    {`Select Video`}
                    <input
                      type="file"
                      onChange={handleFileChange}
                      hidden
                      accept="video/*"
                    />
                  </Button>

                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      backgroundColor: colors.redAccent[600],
                      color: colors.grey[100],
                    }}
                    onClick={handleRemove}
                    disabled={!videoFile}
                  >
                    {`Remove Video`}
                  </Button>
                </Box>
              </Box>
              <ArtistContentUpload
                type="Video"
                setPayload={setPDFData}
                accept="application/pdf"
                name="PDF"
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              {loading ? (
                <CircularProgress
                  color="secondary"
                  sx={{ marginRight: "20px" }}
                />
              ) : (
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ mr: "8px", color: colors.grey[700] }}
                >
                  Upload Video
                </Button>
              )}

              <Button
                variant="contained"
                sx={{
                  backgroundColor: colors.redAccent[600],
                  color: colors.grey[100],
                }}
                onClick={() => navigate("/videos")}
              >
                Cancel
              </Button>
            </Box>
            {error && (
              <Box textAlign="end" sx={{ marginTop: "10px" }}>
                <Typography color="red">Error Please Try Again</Typography>
              </Box>
            )}
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("required"),
  description: yup.string().required("required"),
});
const initialValues = {
  title: "",
  description: "",
};

export default AddVideoPage;

/*
Info needed for a new video

Link artist
Video Title
Video URL
PDF URL
Video Description

*/
