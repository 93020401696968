import { Routes, Route } from "react-router-dom";

import LoginPage from "../pages/LoginPage";
import HomePage from "../pages/HomePage";
import { useAuth } from "../context/AppProvider";
import ArtistPage from "../pages/ArtistPage";
import UsersPage from "../pages/UsersPage";
import InvoicePage from "../pages/InvoicePage";
import AddArtistPage from "../pages/AddArtistPage";
import BarPage from "../pages/BarPage";
import PiePage from "../pages/PiePage";
import LinePage from "../pages/LinePage";
import FAQPage from "../pages/FAQPage";
import GeographyPage from "../pages/GeographyPage";
import EventsPage from "../pages/EventsPage";
import DashboardPage from "../pages/DashboardPage/DashboardPage";

const NoAuthRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<LoginPage />} />
    </Routes>
  );
};

export default NoAuthRoutes;
