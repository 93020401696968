import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

const ArtistCard = ({
  id,
  profileImage,
  name,
  path = "",
  playedForFeatured,
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`${path}${id}`);
  };

  return (
    <Grid
      item
      xs={2}
      onClick={() => onClick()}
      sx={{
        margin: "15px",
        border: "1px solid red",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        border: "8px solid white",
        borderRadius: "6%",
        backgroundColor: "#fff",
        cursor: "pointer",
      }}
    >
      <img
        src={profileImage}
        alt={name}
        loading="lazy"
        style={{
          width: "100%",
          aspectRatio: 2 / 1.5,
          objectFit: "cover",
          objectPosition: "top",
          borderRadius: "6%",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          borderBottomLeftRadius: "6%",
          borderBottomRightRadius: "6%",
          bottom: "0%",
          width: "100%",
          backgroundColor: "#000000",
          padding: "2% 5%",
        }}
      >
        <Typography
          color={"#fff"}
          sx={{
            fontWeight: 600,
            fontFamily: "Montserrat",
            fontSize: "16px",
          }}
        >
          {name}
        </Typography>
        <Typography
          color={"#fff"}
          sx={{
            fontWeight: 400,
            fontFamily: "Montserrat",
            fontSize: "14px",
          }}
        >
          {playedForFeatured}
        </Typography>
      </Box>
    </Grid>
  );
};

export default ArtistCard;
