import { useEffect } from "react";
import axios from "axios";

const jwt = localStorage.getItem("token");

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${jwt}`,
};

const HomePage = () => {
  const getArtists = async () => {
    try {
      const response = await axios.get("/api/v1/artists", { headers });
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getArtists();
  }, []);

  return (
    <div>
      <h1>Home Page</h1>
    </div>
  );
};

export default HomePage;
