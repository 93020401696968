import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes/AppRoutes";
import { AppProvider } from "./context/AppProvider";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <BrowserRouter>
      <AppProvider>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRoutes />
          </ThemeProvider>
        </ColorModeContext.Provider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
