import axios from "axios";

const getJWT = async () => {
  const jwt = localStorage.getItem("token");

  return jwt;
};

const axiosInstance = axios.create({
  baseURL: "https://api.palacedrumclinic.com",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getJWT()}`,
  },
});

export default axiosInstance;
