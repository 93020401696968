import { useAuth } from "../context/AppProvider";

import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";
import AuthRoutes from "./AuthRoutes";
import NoAuthRoutes from "./NoAuthRoutes";

const AppRoutes = () => {
  const { isLoggedIn } = useAuth();
  return (
    <>
      {isLoggedIn ? (
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            <AuthRoutes />
          </main>
        </div>
      ) : (
        <NoAuthRoutes />
      )}
    </>
  );
};

export default AppRoutes;
